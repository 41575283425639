import React, { useState, useEffect } from "react";
import useGetLatestTradesByTokens from "../../../hooks/useGetLatestTradesByTokens"; // Import the updated hook

interface UserCoinsHeldProps {
    user: any;
}

const UserCoinsHeld: React.FC<UserCoinsHeldProps> = ({ user }) => {    
    // Collect all token addresses from the user's held coins
    const tokenAddresses = user?.coinsHeld.map((coin: any) => coin.coin.address) || [];

    // Use the new hook to fetch the latest trades for all token addresses
    const latestTrades = useGetLatestTradesByTokens(tokenAddresses, 5000); // polling every 5 seconds

        // Pagination state
        const [currentPage, setCurrentPage] = useState(1);
        const coinsPerPage = 5; // Adjust this number based on how many coins to show per page
    
        // Calculate the current tokens to display
        const indexOfLastCoin = currentPage * coinsPerPage;
        const indexOfFirstCoin = indexOfLastCoin - coinsPerPage;
        const currentCoins = user?.coinsHeld.slice(indexOfFirstCoin, indexOfLastCoin);
    
        // Total pages based on coins held
        const totalPages = Math.ceil((user?.coinsHeld.length || 0) / coinsPerPage);
    
        // Pagination handlers
        const handleNextPage = () => {
            if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1);
            }
        };
    
        const handlePrevPage = () => {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        };

    return (
        <div className="grid justify-items-right gap-4">
            <div className="flex justify-between items-center mb-4">
                <div className="flex-1 min-w-[150px]" />
                <div className="flex-1 min-w-[150px] flex justify-end">
                    <div className="text-sm hover:underline cursor-pointer w-fit">
                        <div>[Hide dust coins]</div>
                    </div>
                </div>
            </div>
            <div className="grid gap-2">
                {currentCoins?.map((coin: any, index: number) => {
                    // Get the latest trade for the current token address
                    const latestTrade = latestTrades[coin.coin.address];

                    // Calculate the ETH value of the user's holdings
                    let ethValue = 0;
                    if (latestTrade && latestTrade.amountToken && latestTrade.amountETH) {
                        const ethPerToken = latestTrade.amountETH / latestTrade.amountToken;
                        ethValue = ethPerToken * coin.amount;
                    }

                    return (
                        <div
                            key={coin.coin.address} // Ensure to use a unique key for each item in a list
                            className="grid gap-4 text-sm min-w-[350px] w-fit"
                            style={{ gridTemplateColumns: "auto auto 1fr", width: "100%" }}
                        >
                            <img
                                alt="coin logo"
                                loading="lazy"
                                width={40}
                                height={40}
                                decoding="async"
                                data-nimg={1}
                                className="w-10 h-10 rounded-full"
                                src={`${coin.coin.image}?img-width=40&img-dpr=2&img-onerror=redirect`}
                                style={{ color: "transparent", display: "block" }}
                            />
                            <div>
                                <div>{coin.amount.toLocaleString("en-US")} {coin.coin.symbol}</div>
                                <div className="text-green-300" style={{ textAlign: "left" }}>
                                    {ethValue ? ethValue.toFixed(4) : 'Loading...'} ETH
                                </div>
                            </div>
                            <div className="justify-self-end grid justify-items-end">
                                <div className="hover:underline cursor-pointer">[refresh]</div>
                                <a
                                    className="hover:underline cursor-pointer"
                                    href={`/${coin.coin.address}`}
                                >
                                    [view coin]
                                </a>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="w-full flex justify-center mt-4">
                <div className="justify-self-end mb-20">
                    <div className="flex justify-center space-x-2 text-slate-50">
                        <button
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            className={`text-sm ${currentPage === 1 ? "text-slate-400 cursor-not-allowed" : "text-slate-50 hover:font-bold"}`}
                        >
                            [ &lt;&lt; ]
                        </button>
                        <span>{currentPage}</span>
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                            className={`text-sm ${currentPage === totalPages ? "text-slate-400 cursor-not-allowed" : "text-slate-50 hover:font-bold"}`}
                        >
                            [ &gt;&gt; ]
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserCoinsHeld;
