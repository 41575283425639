// define the FarmPage component
import React, { useEffect, useState } from "react";
import "./tokenpage.css";
import { useParams } from "react-router-dom";
import { convertFromURL, formatNumberWithCommas } from "../../../utils";
import { useAccount, useContractWrite, useReadContract, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import useGetTokenHolders from "../../../hooks/useGetTokenHolders";
import PageContainer from "../../../components/PageContainer/PageContainer";
import TokenHolderListItem from "./TokenHolderListItem";
import BubbleMaps from "./BubbleMaps"
import { TokenHolderInfo } from "../../../types";
import PostReply from "./PostReply";
import useTokenInfo from "../../../hooks/useTokenInfo";
import ReplyCard from "./ReplyCard";
import SlippageSettings from "./SlippageSettings";
import TradeModal from "./TradeModal";
import Footer from "../../../components/Footer";
import PumpStationPoolABI from "../../../abis/PumpStationPool.abi.json";
import { erc20Abi, formatUnits, parseUnits, zeroAddress } from "viem";
import useGetTradesByToken from "../../../hooks/useGetTradesByToken";
import TradeCard from "./TradeCard";
import { notifyError, notifySuccess } from "../../../hooks/utils";
import CandlestickChart from "./CandlestickChart";
import { BigNumber } from "ethers";
import { useAuth } from "../../../context/AuthContext";
import useKingOfPump from "../../../hooks/useKingOfPump";
import { DEX_NAME } from "../../../utils/constants";
import LogoPumpStation from "../../../assets/img/logo-pumpstation.png";

const TokenPage = () => {

  const account = useAccount();

  let { tokenAddress } = useParams() as any;

  const {generalInfo: tokenInfo, loading: loadingTokenInfo} = useTokenInfo(tokenAddress);

  const kingOfPump = useKingOfPump();

  const allTrades = useGetTradesByToken(tokenAddress);
  const tokenHoldersData = useGetTokenHolders(tokenAddress);

  const [toggleChatOrTrades, setToggleChatOrTrades] = useState(true);
  const [generateBubbleMapsOpen, setGenerateBubbleMapsOpen] = useState(false);
  const [slippageSettingsOpen, setSlippageSettingsOpen] = useState(false);

  const [toggleBuyTokenAmount, setToggleBuyTokenAmount] = useState(false)
  const [actionTab, setActionTab] = useState(0)
  const [showPostReply, setShowPostReply] = useState(false);
  const [replyTo, setReplyTo] = useState("");

  const [showTradeModal, setShowTradeModal] = useState(false);
  const [tradeType, setTradeType] = useState("buy");

  const [inputTradeValue, setInputTradeValue] = useState("");


  const { data: reserve0 } = useReadContract({
    abi: PumpStationPoolABI,
    address: tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress,
    functionName: 'reserve0',
    args: [],
  });
  const { data: reserve1 } = useReadContract({
    abi: PumpStationPoolABI,
    address: tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress,
    functionName: 'reserve1',
    args: [],
  });
  const { data: totalReceived } = useReadContract({
    abi: PumpStationPoolABI,
    address: tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress,
    functionName: 'getAmountOutPublic',
    args: [parseUnits(inputTradeValue === "" ? "0" : inputTradeValue, 18), tradeType === "buy" ? reserve0 : reserve1, tradeType === "buy" ? reserve1 : reserve0],
  });

  const { data: userTokenBalance } = useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: 'balanceOf',
    args: [account ? account.address as any : zeroAddress],
  });

  const { data: poolBalance } = useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: 'balanceOf',
    args: [tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress],
  });

  const { data: tokenMarketCap } = useReadContract({
    abi: PumpStationPoolABI,
    address: tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress,
    functionName: 'getCurrentCap',
    args: [],
  });

  const getAmountIn = (reservesIn: number, reservesOut: number, tokenOutQuantity: number): number => {
    // Calculate numerator and denominator
    const numerator = reservesIn * tokenOutQuantity;
    const denominator = reservesOut - tokenOutQuantity;
  
    // Return the calculated amount in
    return (numerator / denominator);
  };

  const [slippage, setSlippage] = useState(1);

  const [bondingCurveProgress, setBondingCurveProgress] = useState(0);
  const [kingOfPumpProgress, setKingOfPumpProgress] = useState(0);
  useEffect(() => {
    if (tokenMarketCap) {
      setBondingCurveProgress((Number(formatUnits(tokenMarketCap as any, 18)) / 69420) * 100);
    }
  }, [tokenMarketCap]);

  useEffect(() => {
    if (tokenMarketCap && kingOfPump) {
      setKingOfPumpProgress((Number(formatUnits(tokenMarketCap as any, 18)) / Number(formatUnits(kingOfPump.marketcap as any, 0))) * 100);
    }
  }, [tokenMarketCap, kingOfPump]);


  const handleCopy = () => {
    // Get the input element by ID
    const input = document.getElementById('copy-ca') as HTMLInputElement;

    // Check if input exists and has a value
    if (input && input.value) {
      navigator.clipboard.writeText(input.value)
        .then(() => {
          notifySuccess("Copied to clipboard successfully!");
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  // do a function to set the % of sell based on the userTokenBalance 

  const handleSellPercentage = (percentage: number) => {
    setInputTradeValue((Number(formatUnits(userTokenBalance as any, 18))* percentage / 100).toString());
  }

  const handleBuyAmount = (amount: number) => {
    setInputTradeValue(amount.toString());
  }

  const [hasSufficientAllowance, setHasSufficientAllowance] = useState(false);

  // Read the allowance and refetch on demand
  const { data: allowance, refetch: refetchAllowance } = useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: "allowance",
    args: [account ? account.address as any : zeroAddress, tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress],
  });


  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  const { isSignedIn } = useAuth() as any;
  const [isApproving, setApproving] = useState(false);

  const handleApproveTokens = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!accessToken || !isSignedIn || !account) {
      // Redirect to login or show a message
      alert("You need to sign in to trade.");
      return;
    }

    try {
      setApproving(true);

      const transactionArgs = {
        abi: erc20Abi,
        address: tokenAddress,
        functionName: 'approve',
        args: [tokenInfo ? tokenInfo.pumpStationPool : zeroAddress, parseUnits(inputTradeValue, 18)],
      };

      writeContract(transactionArgs as any);
    } catch (error) {
      console.error("Error approving tokens:", error);
      notifyError("An error occurred while trying to approve tokens.");
    } finally {
      setApproving(false);
    }
  };

  useEffect(() => {
    if (allowance && inputTradeValue) {
      const isAllowed = BigNumber.from(allowance).gte(parseUnits(inputTradeValue, 18));
      setHasSufficientAllowance(isAllowed);
    } else {
      setHasSufficientAllowance(false); // Reset when no allowance or trade value
    }
  }, [allowance, inputTradeValue]); // Update hasSufficientAllowance when allowance or trade value changes

  useEffect(() => {
    // Refetch allowance automatically when input trade value changes
    refetchAllowance();
  }, [inputTradeValue]); // Optionally refetch allowance when input value changes

  useEffect(() => {
    // Refetch allowance when the transaction is confirmed
    if (isConfirmed) {
      refetchAllowance();
    }
  }, [isConfirmed, refetchAllowance]);

  if (!tokenInfo && !loadingTokenInfo) {
    return (
      <PageContainer>
        <main className="h-full" style={{ minHeight: '100vh' }}>
          <div className="text-white flex items-center justify-center text-center h-fit mx-auto p-4">
            <div className="">
              <svg
                className="w-80 mx-auto"
                viewBox="0 0 539 451"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 295.5L492 116C492 116 547.5 135 537.5 228C527.5 321 390 372 390 372L300 345L332.5 408.5C267.5 438.5 213.5 452.5 182.5 450.5C151.5 448.5 115.5 449 74 403.5C32.5 358 36 295.5 36 295.5Z"
                  fill="black"
                  fillOpacity="0.1"
                />
                <path
                  d="M470.947 161.304C432.771 200.378 401.81 229.18 380.427 248.187C369.735 257.69 361.438 264.745 355.831 269.411C355.242 269.901 354.683 270.364 354.154 270.801L233.113 107.167C235.279 105.188 238.461 102.418 243.094 98.6121C257.347 86.902 284.906 65.7353 337.904 28.1688C386.822 -6.50546 437.236 8.80343 467.214 42.2822C497.544 76.1541 505.202 126.243 470.947 161.304Z"
                  fill="white"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M351.065 149.236C367.757 172.887 375.745 198.897 375.516 221.589C375.288 244.295 366.925 262.971 351.737 273.69C336.548 284.409 316.15 286.032 294.679 278.641C273.223 271.254 251.392 255.013 234.7 231.363C218.008 207.712 210.02 181.702 210.248 159.01C210.477 136.304 218.84 117.628 234.028 106.909C249.217 96.1897 269.615 94.5665 291.085 101.958C312.542 109.345 334.373 125.586 351.065 149.236Z"
                  fill="#E1E1E1"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M16.0141 246.223C7.54956 186.447 49.3591 131.097 109.398 122.596L219.075 107.065C219.075 107.065 242.161 156.219 249.79 221.618C257.419 287.017 249.728 323.532 249.728 323.532L140.051 339.062C80.0118 347.564 24.4786 305.999 16.0141 246.223Z"
                  fill="#5FCB88"
                />
                <path
                  d="M110.727 130.019L214.27 115.357C214.602 116.154 214.962 117.031 215.347 117.984C217.577 123.498 220.647 131.564 223.958 141.664C230.587 161.887 238.146 190.155 241.908 222.374C245.694 254.792 245.683 279.958 244.747 296.919C244.279 305.4 243.58 311.832 243.008 316.095C242.991 316.218 242.975 316.34 242.958 316.46L139.208 331.152C83.6668 339.016 32.266 300.367 24.4011 244.826C16.5362 189.285 55.1855 137.884 110.727 130.019Z"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M69.0978 252.344C71.1796 251.763 73.3384 252.979 73.9197 255.061L75.8974 262.144C76.4787 264.226 75.2623 266.385 73.1805 266.966C71.0987 267.548 68.9399 266.331 68.3586 264.249L66.3809 257.166C65.7996 255.084 67.016 252.925 69.0978 252.344ZM79.9079 277.183C81.461 275.68 83.9387 275.721 85.4418 277.274C92.6431 284.715 101.241 289.943 110.217 292.733C112.281 293.374 113.434 295.568 112.792 297.632C112.15 299.696 109.957 300.849 107.893 300.207C97.6304 297.017 87.9063 291.075 79.8173 282.717C78.3141 281.164 78.3547 278.686 79.9079 277.183ZM124.884 300.453C124.554 298.317 126.018 296.318 128.154 295.988L137.353 294.569C139.489 294.239 141.488 295.703 141.818 297.839C142.148 299.976 140.683 301.975 138.547 302.304L129.348 303.724C127.212 304.054 125.213 302.589 124.884 300.453Z"
                  fill="white"
                />
              </svg>
              <h2 className=" text-4xl my-4">Not Found</h2>
              <div className="text-lg mb-4">
                <p className="">
                  Wait and try again later. It seems the coin doesn't exist or is still
                  indexing.
                </p>
                <p>
                  {" "}
                  If it's still not working contact{" "}
                  <a
                    className="text-green-400"
                    href="https://t.me/pumpfunsupport"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support
                  </a>{" "}
                  and send the coin address.
                </p>
              </div>
              <a className="text-green-400" href="/">
                Return Home
              </a>
            </div>
          </div>
        </main>
        <Footer />
      </PageContainer>
    );
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth', // Optional: adds smooth scrolling effect
    });
  };



  return (
    <PageContainer>
      {(showTradeModal && tokenInfo) && (
        <TradeModal
          open={showTradeModal}
          onClose={() => setShowTradeModal(false)}
          tradeType={tradeType}
          tokenName={tokenInfo.symbol}
          tokenAmount={tradeType === "buy" ? !toggleBuyTokenAmount ? formatUnits(totalReceived as any, 18) : inputTradeValue : inputTradeValue}
          ethAmount={tradeType === "buy" ? !toggleBuyTokenAmount ? inputTradeValue : getAmountIn(Number(formatUnits(reserve0 as any, 18)), Number(formatUnits(reserve1 as any, 18)), Number(inputTradeValue)).toString() : formatUnits(totalReceived as any, 18)}
          tokenAddress={tokenInfo.address}
          tokenInfo={tokenInfo}
          slippage={slippage}
        />
      )}
      {generateBubbleMapsOpen && (
        <BubbleMaps
          tokenAddress={tokenAddress}
          open={generateBubbleMapsOpen}
          onClose={() => setGenerateBubbleMapsOpen(false)}
        />
      )}
      {slippageSettingsOpen && (
        <SlippageSettings
          open={slippageSettingsOpen}
          setSlippage={setSlippage}
          slippage={slippage}
          onClose={() => setSlippageSettingsOpen(false)}
        />
      )}
      {showPostReply && (
        <PostReply
          token={tokenAddress}
          open={showPostReply}
          onClose={() => setShowPostReply(false)}
          replyTo={replyTo}
        />
      )}
      {tokenInfo && (
        <main className="h-full" style={{ minHeight: '100vh' }}>
          <div className="md:hidden lg:hidden flex justify-center my-4" />
          <div className="md:block hidden mt-16 p-4 mb-16">
            <div className="flex justify-center">
              <a
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 -mt-5 text-2xl text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50"
                href="/board"
              >
                [go back]
              </a>
            </div>
            <div className="flex space-x-8 mt-4">
              <div className="flex flex-col gap-2 w-2/3">
                <div className="text-xs text-green-300 flex w-full justify-between items-center">
                  <div className="flex gap-4 items-center">
                    <div className="text-gray-400">{tokenInfo.name}</div>
                    <div className="text-gray-400">Ticker: {tokenInfo.symbol}</div>
                    <div>Market cap: ${Number(formatUnits(tokenMarketCap as any || "0", 18)).toFixed(2)}</div>
                    <div className="text-gray-400">
                      <div className="flex items-center">
                        <label htmlFor="copy-ca">CA:</label>
                        <input
                          id="copy-ca"
                          className="py-0.5 px-1 ml-2 block w-full border border-gray-700 rounded-s-sm text-xs focus-visible:outline-1 focus-visible:outline-dashed  disabled:opacity-50 disabled:pointer-events-none bg-black/10"
                          // readOnly=""
                          type="text"
                          disabled
                          defaultValue={`${tokenInfo.address}`}
                        />
                        <button className=" cursor-pointer bg-black/20 px-2 py-0.5 inline-flex items-center min-w-fit rounded-e-sm border border-s-0 border-gray-700 text-xs text-gray-500 hover:bg-black/40 focus-visible:outline-1 focus-visible:outline"
                          onClick={handleCopy}
                        >
                          copy
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="inline-flex items-center gap-2 text-sm">
                    <span>created by</span>
                    <a href={`/profile/${tokenInfo.creator.address}`}>
                      <span className="flex gap-1  items-center">
                        <img
                          alt=""
                          loading="lazy"
                          width={16}
                          height={16}
                          decoding="async"
                          data-nimg={1}
                          className="rounded w-4 h-4"
                          src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect"
                          style={{ color: "transparent", display: "block" }}
                        />
                        <span
                          className="px-1 rounded hover:underline flex gap-1 text-black"
                          style={{ backgroundColor: "rgb(225, 205, 142)" }}
                        >
                          {tokenInfo.creator.address.substring(0, 6)}{" "}
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="h-4/8">
                  <div className="grid h-fit gap-2">
                    <div className="chart-container ">
                      {allTrades && allTrades.length > 0 && <CandlestickChart trades={allTrades} />}
                      {/* <div
                        id="tv-chart-386gfkm09oj"
                        className=""
                        style={{ height: 400, width: "100%" }}
                      >
                        <iframe
                          id="tradingview_f8ce9"
                          name="tradingview_f8ce9"
                          src="blob:https://pump.fun/05ce7c07-75f5-4b2c-b2bc-70fcbf041742"
                          data-widget-options="symbol=BLUNT&interval=5&widgetbar=%7B%22details%22%3Afalse%2C%22watchlist%22%3Afalse%2C%22news%22%3Afalse%2C%22datawindow%22%3Afalse%2C%22watchlist_settings%22%3A%7B%22default_symbols%22%3A%5B%5D%7D%7D&timeFrames=%5B%7B%22text%22%3A%225y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%221y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%226m%22%2C%22resolution%22%3A%22120%22%7D%2C%7B%22text%22%3A%223m%22%2C%22resolution%22%3A%2260%22%7D%2C%7B%22text%22%3A%221m%22%2C%22resolution%22%3A%2230%22%7D%2C%7B%22text%22%3A%225d%22%2C%22resolution%22%3A%225%22%7D%2C%7B%22text%22%3A%221d%22%2C%22resolution%22%3A%221%22%7D%5D&locale=en&uid=tradingview_f8ce9&clientId=0&userId=0&chartsStorageVer=1.0&debug=false&timezone=Etc%2FUTC&theme=dark"
                          title="Financial Chart"
                          frameBorder={0}
                          // allowTransparency="true"
                          scrolling="no"
                          // allowFullScreen=""
                          style={{ display: "block", width: "100%", height: "100%" }}
                        ></iframe>
                      </div> */}
                      {/* <div className="hidden">
                        <div id="dexscreener-embed">
                          <iframe
                            id="geckoterminal-embed"
                            title="GeckoTerminal Embed"
                            src="https://www.geckoterminal.com/solana/pools/null?embed=1&info=0&swaps=0&grayscale=1"
                            allow="clipboard-write"
                            // allowFullScreen=""
                            style={{ height: 400, width: "100%" }}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 h-fit mt-4">
                  <div className={`cursor-pointer px-1 py-1 rounded ${toggleChatOrTrades === true ? "text-black bg-green-300" : "text-gray-500"}`}
                    onClick={() => setToggleChatOrTrades(true)}>
                    Thread
                    {/* {toggleChatOrTrades === true && <span className="w-full h-1 bg-green-300 rounded" />} */}
                  </div>
                  <div className={`cursor-pointer px-1 py-1 rounded ${toggleChatOrTrades === false ? "text-black bg-green-300" : "text-gray-500"}`}
                    onClick={() => setToggleChatOrTrades(false)}>
                    Trades
                    {/* {toggleChatOrTrades === false && <span className="w-full h-1 bg-green-300 rounded" />} */}

                  </div>
                </div>
                {toggleChatOrTrades ? (<div className="text-slate-300 grid gap-1 relative">
                  <div className="hover:underline cursor-pointer text-slate-300 w-fit text-sm" onClick={scrollToBottom}>[scroll to bottom]</div>
                  <div className="gap-1 grid h-fit p-1 text-sm nes-container is-dark">
                    <div className="flex gap-1 text-xs">
                      <a href={`/profile/${tokenInfo.creator.address}`}>
                        <span className="flex gap-1  items-center">
                          <img
                            alt=""
                            loading="lazy"
                            width={16}
                            height={16}
                            decoding="async"
                            data-nimg={1}
                            className="rounded w-4 h-4"
                            src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect"
                            style={{ color: "transparent", display: "block" }}
                          />
                          <span
                            className="px-1 rounded hover:underline flex gap-1 text-black"
                            style={{ backgroundColor: "rgb(225, 205, 142)" }}
                          >
                            {tokenInfo.creator.address.substring(0, 6)} (dev)
                          </span>
                        </span>
                      </a>
                      <div className="text-slate-400">{new Date(tokenInfo.createdAt).toLocaleString()}</div>
                    </div>
                    <div className="relative items-start gap-3 text-slate-300 text-xs w-fit flex">
                      <img
                        alt="name"
                        loading="lazy"
                        width={800}
                        height={800}
                        decoding="async"
                        data-nimg={1}
                        className="w-32 object-contain cursor-pointer max-h-screen"
                        src={`${tokenInfo.image}?img-width=800&img-dpr=2&img-onerror=redirect`}
                        style={{ color: "transparent", display: "block" }}
                      />
                      <div className="grid" style={{textAlign: "left"}}>
                        <div className="font-bold" style={{marginBottom: "10px"}}>{tokenInfo.name} (ticker: {tokenInfo.symbol})</div>
                        <div className="break-anywhere">{tokenInfo.description}</div>
                      </div>
                    </div>
                  </div>
                  {tokenInfo.replyIds.map((reply: any) => (
                    <ReplyCard key={reply.replyId} reply={reply} setShowPostReply={setShowPostReply} setReplyTo={setReplyTo} tokenInfo={tokenInfo} />
                  ))}

                  <div className="justify-self-center hover:underline cursor-pointer" onClick={() => { setShowPostReply(true); setReplyTo("") }}>
                    [Post a reply]
                  </div>
                </div>
                ) : (
                  <div className="w-full text-xs sm:text-sm text-gray-400 bg-transparent rounded-lg">
                    <div className="flex items-center gap-2 mb-3">
                      <label htmlFor="tradesByFollowing">Filter by following</label>
                      <button
                        type="button"
                        role="switch"
                        aria-checked="false"
                        data-state="unchecked"
                        value="on"
                        className="peer inline-flex h-4 w-11 shrink-0 cursor-pointer items-center rounded border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-green-300 data-[state=unchecked]:bg-slate-500 dark:focus-visible:ring-slate-300 dark:focus-visible:ring-offset-slate-950 dark:data-[state=checked]:bg-slate-50 dark:data-[state=unchecked]:bg-slate-800"
                        id="tradesByFollowing"
                      >
                        <span
                          data-state="unchecked"
                          className="pointer-events-none block h-3 w-5 rounded-[.2rem] bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0 dark:bg-slate-950"
                        />
                      </button>{" "}
                      (0 trades from people you follow)
                    </div>
                    <div className="overflow-x-auto bg-[#2e303a] rounded-lg">
                      <table className="min-w-full divide-y divide-gray-700">
                        <thead className="bg-[#2e303a]">
                          <tr>
                            <th className="p-3 text-left font-normal">account</th>
                            <th className="p-3 text-left font-normal ">type</th>
                            <th className="p-3 text-left font-normal">ETH</th>
                            <th className="p-3 text-left font-normal">{tokenInfo.symbol}</th>
                            <th
                              className="p-3 text-left font-normal hidden md:table-cell"
                              style={{ width: 150 }}
                            >
                              <div className="flex items-center">
                                date{" "}
                                <button className="ml-1 inline-block align-middle hover:text-gray-300">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth={0}
                                    viewBox="0 0 24 24"
                                    className="cursor-pointer"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ position: "relative", top: 1 }}
                                  >
                                    <path d="M12 4C14.7486 4 17.1749 5.38626 18.6156 7.5H16V9.5H22V3.5H20V5.99936C18.1762 3.57166 15.2724 2 12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4ZM20 12C20 16.4183 16.4183 20 12 20C9.25144 20 6.82508 18.6137 5.38443 16.5H8V14.5H2V20.5H4V18.0006C5.82381 20.4283 8.72764 22 12 22C17.5228 22 22 17.5228 22 12H20Z" />
                                  </svg>
                                </button>
                              </div>
                            </th>
                            <th className="p-3 text-right font-normal hidden sm:table-cell">
                              transaction
                            </th>
                            <th className="p-3 text-right font-normal sm:hidden">txn</th>
                          </tr>
                        </thead>
                        <tbody className="bg-[#2e303a] divide-y divide-gray-700">
                          {allTrades && allTrades.map((trade: any) => (
                            <TradeCard key={trade.tradeId} trade={trade} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="w-full flex justify-center mt-4">
                      <div className="justify-self-end mb-20">
                        <div className="flex justify-center space-x-2 text-slate-50">
                          <button
                            disabled
                            className="text-sm text-slate-400 cursor-not-allowed"
                          >
                            [ &lt;&lt; ]
                          </button>
                          <span>1</span>
                          <button
                            className="text-sm text-slate-400 cursor-not-allowed"
                            disabled
                          >
                            [ &gt;&gt; ]
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
              <div className="w-1/3 grid gap-4 h-fit w-fit">
                <div className="w-full md:w-[350px] grid gap-4">
                  <div className="bg-[#2e303a] p-4 grid gap-4 nes-container is-rounded is-dark">
                    <div className="grid grid-cols-2 gap-2 mb-4">
                      <button className={`p-2 text-center rounded ${actionTab === 0 ? "nes-btn is-success" : "nes-btn"}`} onClick={() => {setActionTab(0); setTradeType("buy");}}>
                        Buy
                      </button>
                      <button className={`p-2 text-center rounded ${actionTab === 1 ? "nes-btn is-error" : "nes-btn"}`} onClick={() => {setActionTab(1); setTradeType("sell")}}>
                        Sell
                      </button>
                    </div>
                    {actionTab === 0 && (
                      <>
                        <div className="flex justify-between w-full gap-2">
                          <button className="text-xs py-1 px-2 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300"
                            onClick={() => setToggleBuyTokenAmount(!toggleBuyTokenAmount)}
                          >
                            switch to {toggleBuyTokenAmount ? "ETH" : tokenInfo.symbol}
                          </button>
                          <button
                            className="text-xs py-1 px-2 rounded text-gray-400 hover:bg-gray-800 bg-primary"
                            type="button"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="radix-:rf:"
                            data-state="closed"
                            onClick={() => setSlippageSettingsOpen(true)}
                          >
                            Set max slippage
                          </button>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex items-center rounded-md relative bg-[#2e303a]">
                            <input
                              className="flex h-10 rounded-md border border-slate-200 px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 bg-transparent text-white outline-none w-full pl-3"
                              id="amount"
                              placeholder={"0.0"}
                              type="number"
                              onChange={(e) => setInputTradeValue(e.target.value)}
                              value={inputTradeValue}
                            />
                            <div className="flex items-center ml-2 absolute right-2">
                              <span className="text-white mr-2">{!toggleBuyTokenAmount ? "ETH" : tokenInfo.symbol}</span>
                              <img
                                alt={!toggleBuyTokenAmount ? tokenInfo.symbol : "ETH"}
                                loading="lazy"
                                width={32}
                                height={32}
                                decoding="async"
                                data-nimg={1}
                                className="w-8 h-8 rounded-full"
                                src={!toggleBuyTokenAmount ? "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" : tokenInfo.image}
                                style={{ color: "transparent" }}
                              />
                            </div>
                          </div>
                          {!toggleBuyTokenAmount && (
                            <div className="flex mt-2 bg-[#2e303a] p-1 rounded-lg">
                              <button className="text-xs py-1 -ml-1 px-2 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(0)}>
                                reset
                              </button>
                              <button className="text-xs py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(0.05)}>
                                0.05 ETH
                              </button>
                              <button className="text-xs py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(0.1)}>
                                0.1 ETH
                              </button>
                              <button className="text-xs py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(0.5)}>
                                0.5 ETH
                              </button>
                              <button className="text-xs py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(1)}>
                                1 ETH
                              </button>
                            </div>
                          )}
                        </div>
                        <span className="text-sm text-gray-400">{totalReceived ? !toggleBuyTokenAmount ? Number(formatUnits(totalReceived as any, 18)).toLocaleString("en-US") : getAmountIn(Number(formatUnits(reserve0 as any, 18)), Number(formatUnits(reserve1 as any, 18)), Number(inputTradeValue)).toLocaleString("en-US") : 0} {!toggleBuyTokenAmount ? tokenInfo.symbol : "ETH"}</span>
                        <button className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium nes-btn is-success w-full py-3 rounded-md"
                          onClick={() => { setShowTradeModal(true); setTradeType("buy") }}
                        >
                          place trade
                        </button>
                      </>
                    )}
                    {actionTab === 1 && (
                      <>
                        <div className="flex justify-between w-full gap-2">
                          <button
                            className="text-xs py-1 px-2 rounded text-gray-400 hover:bg-gray-800 bg-primary"
                            type="button"
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="radix-:rf:"
                            onClick={() => setSlippageSettingsOpen(true)}
                          >
                            Set max slippage
                          </button>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex items-center rounded-md relative bg-[#2e303a]">
                            <input
                              className="flex h-10 rounded-md border border-slate-200 px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 bg-transparent text-white outline-none w-full pl-3"
                              id="amount"
                              placeholder={"0.0"}
                              type="number"
                              onChange={(e) => setInputTradeValue(e.target.value)}
                              value={inputTradeValue}
                            />
                            <div className="flex items-center ml-2 absolute right-2">
                              <span className="text-white mr-2">{tokenInfo.symbol}</span>
                              <img
                                alt={tokenInfo.symbol}
                                loading="lazy"
                                width={32}
                                height={32}
                                decoding="async"
                                data-nimg={1}
                                className="w-8 h-8 rounded-full"
                                src={tokenInfo.image}
                                style={{ color: "transparent" }}
                              />
                            </div>
                          </div>
                          <div className="flex mt-2 bg-[#2e303a] p-1 rounded-lg">
                            <button className="text-xs py-1 -ml-1 px-2 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(0)}>
                              reset
                            </button>
                            <button className="text-xs py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(25)}>
                              25%
                            </button>
                            <button className="text-xs py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(50)}>
                              50%
                            </button>
                            <button className="text-xs py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(75)}>
                              75%
                            </button>
                            <button className="text-xs py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(100)}>
                              100%
                            </button>
                          </div>

                        </div>
                        <span className="text-sm text-gray-400">{totalReceived ? formatUnits(totalReceived as any, 18) : 0} ETH</span>
                        {/* <button className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium nes-btn is-error w-full py-3 rounded-md"
                          onClick={() => { setShowTradeModal(true); setTradeType("sell") }}>
                          place trade
                        </button> */}
                        {hasSufficientAllowance ? (
            <button
              className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium nes-btn is-error w-full py-3 rounded-md"
              onClick={() => { setShowTradeModal(true); setTradeType("sell") }}
            >
              Place Trade
            </button>
          ) : (
            <button
              className="inline-flex items-center justify-center whitespace-nowrap text-sm font-medium nes-btn is-warning w-full py-3 rounded-md"
              disabled={isApproving || isPending || isConfirming}
              onClick={handleApproveTokens}
            >
              {(isApproving || isPending || isConfirming) ? "Approving..." : "Approve Tokens"}
            </button>
          )}
                      </>
                    )}

                  </div>
                </div>
                <div className="w-[350px] bg-transparent text-gray-400 rounded-lg border border-none grid gap-4">
                  <div className="flex gap-4">
                    <a
                      href={tokenInfo.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" text-gray-400 hover:underline text-sm nes-text"
                    >
                      [twitter]
                    </a>
                    <a
                      href={tokenInfo.telegram}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" text-gray-400 hover:underline text-sm nes-text"
                    >
                      [telegram]
                    </a>
                    <a
                      href={tokenInfo.website}
                      target="_blank"
                      rel="noopener noreferrer"
                      className=" text-gray-400 hover:underline text-sm nes-text"
                    >
                      [website]
                    </a>
                  </div>
                  <div className="gap-3 h-fit items-start flex">
                    <img
                      alt=""
                      loading="lazy"
                      width={256}
                      height={256}
                      decoding="async"
                      data-nimg={1}
                      className="w-32 object-contain cursor-pointer"
                      src={`${tokenInfo.image}?img-width=256&img-dpr=2&img-onerror=redirect`}
                      style={{ color: "transparent", display: "block" }}
                    />
                    <div>
                      <div className="font-bold text-sm">{tokenInfo.name} (ticker: {tokenInfo.symbol})</div>
                      <div className="text-xs text-gray-400 break-anywhere">
                        {tokenInfo.description}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-400 mb-1">
                      bonding curve progress: {bondingCurveProgress.toLocaleString("en-US")}%
                    </div>
                    <progress className="nes-progress is-pattern" value={bondingCurveProgress} max="100"></progress>

                  </div>
                  <div className="text-xs text-gray-400">
                    when the market cap reaches $69,420 all the liquidity from the
                    bonding curve will be deposited into {DEX_NAME} and burned. progression
                    increases as the price goes up.
                    <br />
                    <br />
                    there are {formatNumberWithCommas(Number(formatUnits(poolBalance || BigInt(0), 18)))} tokens still available for sale in
                    the bonding curve and there is {reserve0 ? Number(formatUnits(reserve0 as any, 18)) : "-"} ETH in the bonding curve.
                  </div>
                  <div className="grid gap-2">
                    <div className="text-sm text-gray-400">
                      king of the pump progress: {kingOfPumpProgress.toLocaleString("en-US")}%
                    </div>
                    <progress className="nes-progress is-warning" value={kingOfPumpProgress} max="100"></progress>
                    <div className="text-xs text-gray-400">
                      dethrone the current king at a ${kingOfPump ? Number(kingOfPump.marketcap).toLocaleString("en-US") : "-"} market cap
                    </div>
                  </div>
                  <div className="grid gap-2">
                    <div className="font-bold flex justify-between items-center">
                      Holder distribution{" "}
                      <button className="whitespace-nowrap text-xs font-medium ring-offset-white transition-colors  py-1 px-2 rounded text-gray-400 hover:bg-gray-600 bg-gray-700"
                        onClick={() => setGenerateBubbleMapsOpen(true)}>
                        Generate bubble map
                      </button>
                    </div>
                    <div className="text-sm">
                      <div className="grid gap-1">
                        {tokenHoldersData?.map((holder: TokenHolderInfo, index: number) => (
                          <TokenHolderListItem key={index} position={index + 1} tokenHolder={holder} tokenInfo={tokenInfo} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
        {/* <main className="h-full" style={{ minHeight: '100vh' }}>
          <div className="text-white flex items-center justify-center text-center mt-20 h-fit mx-auto p-4">
            <div className="">
              <svg
                className="w-80 mx-auto"
                viewBox="0 0 539 451"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 295.5L492 116C492 116 547.5 135 537.5 228C527.5 321 390 372 390 372L300 345L332.5 408.5C267.5 438.5 213.5 452.5 182.5 450.5C151.5 448.5 115.5 449 74 403.5C32.5 358 36 295.5 36 295.5Z"
                  fill="black"
                  fillOpacity="0.1"
                />
                <path
                  d="M470.947 161.304C432.771 200.378 401.81 229.18 380.427 248.187C369.735 257.69 361.438 264.745 355.831 269.411C355.242 269.901 354.683 270.364 354.154 270.801L233.113 107.167C235.279 105.188 238.461 102.418 243.094 98.6121C257.347 86.902 284.906 65.7353 337.904 28.1688C386.822 -6.50546 437.236 8.80343 467.214 42.2822C497.544 76.1541 505.202 126.243 470.947 161.304Z"
                  fill="white"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M351.065 149.236C367.757 172.887 375.745 198.897 375.516 221.589C375.288 244.295 366.925 262.971 351.737 273.69C336.548 284.409 316.15 286.032 294.679 278.641C273.223 271.254 251.392 255.013 234.7 231.363C218.008 207.712 210.02 181.702 210.248 159.01C210.477 136.304 218.84 117.628 234.028 106.909C249.217 96.1897 269.615 94.5665 291.085 101.958C312.542 109.345 334.373 125.586 351.065 149.236Z"
                  fill="#E1E1E1"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M16.0141 246.223C7.54956 186.447 49.3591 131.097 109.398 122.596L219.075 107.065C219.075 107.065 242.161 156.219 249.79 221.618C257.419 287.017 249.728 323.532 249.728 323.532L140.051 339.062C80.0118 347.564 24.4786 305.999 16.0141 246.223Z"
                  fill="#5FCB88"
                />
                <path
                  d="M110.727 130.019L214.27 115.357C214.602 116.154 214.962 117.031 215.347 117.984C217.577 123.498 220.647 131.564 223.958 141.664C230.587 161.887 238.146 190.155 241.908 222.374C245.694 254.792 245.683 279.958 244.747 296.919C244.279 305.4 243.58 311.832 243.008 316.095C242.991 316.218 242.975 316.34 242.958 316.46L139.208 331.152C83.6668 339.016 32.266 300.367 24.4011 244.826C16.5362 189.285 55.1855 137.884 110.727 130.019Z"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M69.0978 252.344C71.1796 251.763 73.3384 252.979 73.9197 255.061L75.8974 262.144C76.4787 264.226 75.2623 266.385 73.1805 266.966C71.0987 267.548 68.9399 266.331 68.3586 264.249L66.3809 257.166C65.7996 255.084 67.016 252.925 69.0978 252.344ZM79.9079 277.183C81.461 275.68 83.9387 275.721 85.4418 277.274C92.6431 284.715 101.241 289.943 110.217 292.733C112.281 293.374 113.434 295.568 112.792 297.632C112.15 299.696 109.957 300.849 107.893 300.207C97.6304 297.017 87.9063 291.075 79.8173 282.717C78.3141 281.164 78.3547 278.686 79.9079 277.183ZM124.884 300.453C124.554 298.317 126.018 296.318 128.154 295.988L137.353 294.569C139.489 294.239 141.488 295.703 141.818 297.839C142.148 299.976 140.683 301.975 138.547 302.304L129.348 303.724C127.212 304.054 125.213 302.589 124.884 300.453Z"
                  fill="white"
                />
              </svg>
              <h2 className=" text-4xl my-4">Something went wrong</h2>
              <div className="text-lg mb-4">
                <p className="">Wait and try again later. Something failed.</p>
                <p>
                  {" "}
                  If it's still not working after 1 to 5 minutes, contact{" "}
                  <a
                    className="text-green-400"
                    href="https://t.me/pumpfunsupport"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support
                  </a>{" "}
                  and send the coin address.
                </p>
              </div>
              <button className="text-green-400">Try again</button>
            </div>
          </div>
        </main> */}
        {loadingTokenInfo && (
          <main className="h-full" style={{ minHeight: '100vh' }}>
          <div className="text-white flex items-center justify-center text-center mt-20 h-fit mx-auto p-4">
            <div className="">
              {/* <svg
                className="w-80 mx-auto"
                viewBox="0 0 539 451"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 295.5L492 116C492 116 547.5 135 537.5 228C527.5 321 390 372 390 372L300 345L332.5 408.5C267.5 438.5 213.5 452.5 182.5 450.5C151.5 448.5 115.5 449 74 403.5C32.5 358 36 295.5 36 295.5Z"
                  fill="black"
                  fillOpacity="0.1"
                />
                <path
                  d="M470.947 161.304C432.771 200.378 401.81 229.18 380.427 248.187C369.735 257.69 361.438 264.745 355.831 269.411C355.242 269.901 354.683 270.364 354.154 270.801L233.113 107.167C235.279 105.188 238.461 102.418 243.094 98.6121C257.347 86.902 284.906 65.7353 337.904 28.1688C386.822 -6.50546 437.236 8.80343 467.214 42.2822C497.544 76.1541 505.202 126.243 470.947 161.304Z"
                  fill="white"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M351.065 149.236C367.757 172.887 375.745 198.897 375.516 221.589C375.288 244.295 366.925 262.971 351.737 273.69C336.548 284.409 316.15 286.032 294.679 278.641C273.223 271.254 251.392 255.013 234.7 231.363C218.008 207.712 210.02 181.702 210.248 159.01C210.477 136.304 218.84 117.628 234.028 106.909C249.217 96.1897 269.615 94.5665 291.085 101.958C312.542 109.345 334.373 125.586 351.065 149.236Z"
                  fill="#E1E1E1"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M16.0141 246.223C7.54956 186.447 49.3591 131.097 109.398 122.596L219.075 107.065C219.075 107.065 242.161 156.219 249.79 221.618C257.419 287.017 249.728 323.532 249.728 323.532L140.051 339.062C80.0118 347.564 24.4786 305.999 16.0141 246.223Z"
                  fill="#5FCB88"
                />
                <path
                  d="M110.727 130.019L214.27 115.357C214.602 116.154 214.962 117.031 215.347 117.984C217.577 123.498 220.647 131.564 223.958 141.664C230.587 161.887 238.146 190.155 241.908 222.374C245.694 254.792 245.683 279.958 244.747 296.919C244.279 305.4 243.58 311.832 243.008 316.095C242.991 316.218 242.975 316.34 242.958 316.46L139.208 331.152C83.6668 339.016 32.266 300.367 24.4011 244.826C16.5362 189.285 55.1855 137.884 110.727 130.019Z"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M69.0978 252.344C71.1796 251.763 73.3384 252.979 73.9197 255.061L75.8974 262.144C76.4787 264.226 75.2623 266.385 73.1805 266.966C71.0987 267.548 68.9399 266.331 68.3586 264.249L66.3809 257.166C65.7996 255.084 67.016 252.925 69.0978 252.344ZM79.9079 277.183C81.461 275.68 83.9387 275.721 85.4418 277.274C92.6431 284.715 101.241 289.943 110.217 292.733C112.281 293.374 113.434 295.568 112.792 297.632C112.15 299.696 109.957 300.849 107.893 300.207C97.6304 297.017 87.9063 291.075 79.8173 282.717C78.3141 281.164 78.3547 278.686 79.9079 277.183ZM124.884 300.453C124.554 298.317 126.018 296.318 128.154 295.988L137.353 294.569C139.489 294.239 141.488 295.703 141.818 297.839C142.148 299.976 140.683 301.975 138.547 302.304L129.348 303.724C127.212 304.054 125.213 302.589 124.884 300.453Z"
                  fill="white"
                />
              </svg> */}
              <img src={LogoPumpStation} width={200} height={200} alt="Pump Station" className="mx-auto" />
              <h2 className=" text-4xl my-4">Loading...</h2>
            </div>
          </div>
        </main>
        )}
      
      <Footer>
        {tokenInfo ? (<a
          rel="noopener noreferrer"
          className="flex-shrink-0"
          href={`/report/coins/${tokenInfo.address}`}
        >
          Report
        </a>) : null}
      </Footer>
    </PageContainer>
  );
}
export default TokenPage;