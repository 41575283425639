// src/components/Header.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import "./header.css";
import PumpStationLogo from "../../assets/img/logo-pumpstation.png";
import HowItWorks from '../../views/MainPage/components/HowItWorks';
import { useAccount, useSignMessage } from 'wagmi';
import { useAuth } from '../../context/AuthContext';
import SignInModal from './SignInModal';
import useAllTokensInfo from '../../hooks/useAllTokensInfo';
import useGetLatestTrade from '../../hooks/useGetLatestTrade';

const Header = () => {
    const path = window.location.pathname;

    const allTokens = useAllTokensInfo();

    const latestTrade = useGetLatestTrade();

    // get latest like [0] and [1] for the latest two
    const latestToken = allTokens ? allTokens[0] : null;

    const account = useAccount();
    const { isSignedIn, loading: loadingSignStatus } = useAuth(); // Use the hook to get auth status and functions
    const { address, isConnected } = useAccount();

    const [showHowItWorks, setShowHowItWorks] = useState(false);

    return (

        <nav className="flex flex-wrap justify-between w-full p-2 items-start h-fit bg-primary">
            {showHowItWorks && (
                <HowItWorks
                    open={showHowItWorks}
                    onClose={() => setShowHowItWorks(false)}
                />
            )}
            {(isConnected && !isSignedIn && !loadingSignStatus) && (
                <SignInModal
                    open={isConnected && !isSignedIn}
                />
            )}
            <div className="flex gap-2 items-center">
                <a href="/board">
                    <img
                        alt="PumpStation"
                        loading="lazy"
                        width={42}
                        height={42}
                        decoding="async"
                        data-nimg={1}
                        className="mr-4"
                        style={{ color: "transparent" }}
                        src={PumpStationLogo}
                    />
                </a>
                <div className="grid h-fit header-links">
                    <div className="flex gap-2">
                        <a
                            className="text-sm nes-text text-white"
                            href="https://twitter.com/pump_station"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            [twitter]
                        </a>
                        <a
                            className="text-sm nes-text text-white"
                            href="https://discord.gg/kJBeZRecwF"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            [discord]
                        </a>
                    </div>
                    <div className="flex gap-2">
                        <a
                            className="text-sm nes-text text-white"
                            href="https://t.me/PumpStation_meme"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            [telegram]
                        </a>
                        <button
                            className="text-sm nes-text text-white"
                            onClick={() => setShowHowItWorks(true)}
                        >
                            [how it works]
                        </button>
                    </div>
                </div>
                <div className="hidden md:flex gap-2">
                    {latestTrade && (
                    <div className="nes-badge text-xs" style={{ display: 'flex', height: "100%", maxHeight: "60px", width: "100%", fontSize: "10px" }}>
                        <span className='is-dark flex' style={{ width: '100%', position: "relative" }}>
                            <a href={`/profile/${latestTrade.user.username}`}>
                                <span className="flex gap-1 items-center">
                                    <img
                                        alt=""
                                        loading="lazy"
                                        width={16}
                                        height={16}
                                        decoding="async"
                                        data-nimg={1}
                                        className="rounded-full w-4 h-4"
                                        src={`${latestTrade.user.profileImage}?img-width=16&img-dpr=2&img-onerror=redirect`}
                                        style={{ color: "transparent", display: "block" }}
                                    />
                                    <span
                                        className="px-1 rounded hover:underline flex gap-1"
                                        style={{ backgroundColor: "transparent" }}
                                    >
                                        {latestTrade.user.address === latestTrade.user.username ? latestTrade.user.address.substring(0, 6) : latestTrade.user.username}{" "}
                                    </span>
                                </span>
                            </a>
                            {latestTrade.type === "buy" ? "bought" : "sold"} {latestTrade.type === "buy" ? latestTrade.amountETH.toLocaleString("en-US", {maximumSignificantDigits: 1}) + " ETH" : latestTrade.amountETH.toLocaleString("en-US", {maximumSignificantDigits: 1}) + " ETH"}
                            <a
                                className="hover:underline flex gap-2"
                                href={`/${latestTrade.token.address}`}
                            >
                                &nbsp;{latestTrade.token.symbol}
                                <img
                                    alt=""
                                    loading="lazy"
                                    width={20}
                                    height={20}
                                    decoding="async"
                                    data-nimg={1}
                                    className="h-4 w-4 rounded-full"
                                    src={`${latestTrade.token.image}?img-width=20&img-dpr=2&img-onerror=redirect`}
                                    style={{ color: "transparent", display: "block" }}
                                />
                            </a>
                        </span>
                    </div>
                    )}
                    {latestToken && (
                        <div className="nes-badge text-xs" style={{ display: 'flex', height: "100%", maxHeight: "60px", width: "100%", fontSize: "10px" }}>
                            <span className='is-dark flex' style={{ width: '100%', position: "relative" }}>
                                <span className="relative flex shrink-0 overflow-hidden rounded-full w-4 h-4">
                                    <img
                                        className="aspect-square h-full w-full"
                                        alt="anon"
                                        src="https://www.pinclipart.com/picdir/big/184-1843111_pepe-the-frog-crying-png-clipart.png"
                                    />
                                </span>
                                <a href={`/profile/${latestToken.creator.username}`}>
                                    <span className="hover:underline">{latestToken.creator.address === latestToken.creator.username ? latestToken.creator.address.substring(0, 6) : latestToken.creator.username} </span>
                                </a>
                                <span>&nbsp;created&nbsp;</span>
                                <a
                                    className="hover:underline flex gap-2"
                                    href={`/${latestToken.address}`}
                                >
                                    {latestToken.symbol}
                                    <img
                                        alt=""
                                        loading="lazy"
                                        width={16}
                                        height={16}
                                        decoding="async"
                                        data-nimg={1}
                                        className="rounded-full"
                                        src={`${latestToken.image}?img-width=20&img-dpr=2&img-onerror=redirect`}
                                        style={{ color: "transparent", display: "block" }}
                                    />
                                </a>
                                <span>&nbsp;&nbsp;on {new Date(latestToken.createdAt).toLocaleDateString('en-US', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric',
                                })} </span>
                            </span>
                        </div>
                    )}
                </div>
            </div>
            <div className="md:flex md:gap-2 grid gap-1 items-center" style={{ alignSelf: 'center' }}>
                <div className="grid justify-items-end items-center">
                    <ConnectButton.Custom>
                        {({
                            account,
                            chain,
                            openAccountModal,
                            openChainModal,
                            openConnectModal,
                            authenticationStatus,
                            mounted,
                        }) => {
                            // Note: If your app doesn't use authentication, you
                            // can remove all 'authenticationStatus' checks
                            const ready = mounted && authenticationStatus !== 'loading';
                            const connected =
                                ready &&
                                account &&
                                chain &&
                                (!authenticationStatus ||
                                    authenticationStatus === 'authenticated');

                            return (
                                <div
                                    {...(!ready && {
                                        'aria-hidden': true,
                                        'style': {
                                            opacity: 0,
                                            pointerEvents: 'none',
                                            userSelect: 'none',
                                        },
                                    })}
                                >
                                    {(() => {
                                        if (!connected) {
                                            return (
                                                <button
                                                    className="text-sm text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50"
                                                    type="button"
                                                    aria-haspopup="dialog"
                                                    aria-expanded="false"
                                                    onClick={openConnectModal}
                                                    aria-controls="radix-:r3:"
                                                    data-state="closed"
                                                >
                                                    [connect wallet]
                                                </button>

                                            );
                                        }

                                        if (chain.unsupported) {
                                            return (
                                                <button
                                                    className="text-sm text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50"
                                                    type="button"
                                                    aria-haspopup="dialog"
                                                    aria-expanded="false"
                                                    onClick={openChainModal}
                                                    aria-controls="radix-:r3:"
                                                    data-state="closed"
                                                >
                                                    [wrong network]
                                                </button>
                                            );
                                        }

                                        return (
                                            <div className='text-white text-sm' style={{ display: 'flex', gap: 12 }}>
                                                {/* <button
                                                    onClick={openChainModal}
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                    type="button"
                                                >
                                                    {chain.hasIcon && (
                                                        <div
                                                            style={{
                                                                background: chain.iconBackground,
                                                                width: 12,
                                                                height: 12,
                                                                borderRadius: 999,
                                                                overflow: 'hidden',
                                                                marginRight: 4,
                                                            }}
                                                        >
                                                            {chain.iconUrl && (
                                                                <img
                                                                    alt={chain.name ?? 'Chain icon'}
                                                                    src={chain.iconUrl}
                                                                    style={{ width: 12, height: 12 }}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                    {chain.name}
                                                </button> */}

                                                <button onClick={openAccountModal} type="button">
                                                    <div className="flex items-center gap-1 border border-slate-500 rounded px-1 cursor-pointer hover:bg-slate-600" style={{ border: "1px solid" }}>
                                                        <span className="hidden sm:block">{account.displayBalance
                                                            ? ` (${account.displayBalance})`
                                                            : ''} </span>
                                                        <div>
                                                            <img
                                                                alt=""
                                                                loading="lazy"
                                                                width={16}
                                                                height={16}
                                                                decoding="async"
                                                                data-nimg={1}
                                                                className="w-4 h-4 rounded-full object-contain"
                                                                src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect"
                                                                style={{ color: "transparent", display: "block" }}
                                                            />
                                                        </div>
                                                        <div>{account.displayName}</div>
                                                        <svg
                                                            width={15}
                                                            height={15}
                                                            viewBox="0 0 15 15"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M4 6H11L7.5 10.5L4 6Z" fill="currentColor" />
                                                        </svg>
                                                    </div>
                                                </button>
                                            </div>
                                        );
                                    })()}
                                </div>
                            );
                        }}
                    </ConnectButton.Custom>
                    {(account && address && isSignedIn) && (
                        <a
                            className="text-white text-sm hover:underline relative"
                            href={`/profile/${account.address}`}
                        >
                            [view profile]
                        </a>
                    )}
                </div>
            </div>
        </nav>

    );
};

export default Header;
