// define the CreateToken component
import React, { useEffect, useState } from "react";
import PageContainer from "../../../components/PageContainer/PageContainer";
import { useAuth } from "../../../context/AuthContext";
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import axios from "axios";
import { FACTORY_CONTRACT, getBaseApiUrl } from "../../../utils/constants";
import { uploadImageToFreeImage } from "../../../utils";
import Footer from "../../../components/Footer";
import PumpStationFactoryABI from "../../../abis/PumpStationFactory.abi.json";
import { parseUnits } from "viem";
import { notifyError, notifySuccess, notifyWarning } from "../../../hooks/utils";

const CreateToken = () => {
  const [toggleMoreOptions, setToggleMoreOptions] = useState(false);

  const account = useAccount();

  const { isSignedIn } = useAuth() as any;

  const [formData, setFormData] = useState({
    name: "",
    ticker: "",
    description: "",
    image: null,
    twitter: "",
    telegram: "",
    website: ""
  });
  const [errors, setErrors] = useState({} as any);

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleImageChange = (e: any) => {
    setFormData((prevData) => ({
      ...prevData,
      image: e.target.files[0],
    }));
  };

  const validateForm = () => {
    const newErrors = {} as any;
    if (!formData.name) newErrors.name = "Coin needs a name";
    if (!formData.ticker) newErrors.ticker = "Ticker is required";
    if (!formData.description) newErrors.description = "Description is required";
    // Add more validation as needed

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [creatingToken, setCreatingToken] = useState(false);

  const { data: hash, error, isPending, writeContract } = useWriteContract()

    // Log the transaction arguments and hash for debugging
    useEffect(() => {
      if (hash) {
        console.log("Transaction hash:", hash);
        notifySuccess("Token created successfully!");
      }
      if (error) {
        console.error("Error during contract write:", error);
        notifyError("An error occurred while trying to create token.");
      }
    }, [hash, error]);

  const handleSubmit = async () => {
    setCreatingToken(true);
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!validateForm()) {
      console.log("Validation errors:", errors);
      return;
    }

    if (!accessToken || !isSignedIn || !account) {
      // Redirect to login or show a message
      notifyWarning("You need to sign in to create a token");
      return;
    }

    // Upload the image to FreeImage API if an image is selected
    let uploadedImgUrl;
    if (formData.image) {
      uploadedImgUrl = await uploadImageToFreeImage(formData.image);
      if (!uploadedImgUrl) {
        notifyError("Failed to upload image. Please try again.");
        return;
      }
    }

    try {
      writeContract({
        address: FACTORY_CONTRACT,
        abi: PumpStationFactoryABI,
        functionName: 'createPumpStation', // Replace with your actual contract function
        args: [
          formData.name,          // name
          formData.ticker,        // symbol
          formData.description,   // description
          uploadedImgUrl,         // image
          formData.twitter,       // twitter handle
          formData.telegram,      // telegram handle
          formData.website,       // website link
        ],
        value: parseUnits("0.0001", 18), // 0.0001 ETH
      });

      // setCreatingToken(false);
    } catch (error) {
      console.error("Error creating token:", error);
      notifyError("An error occurred while trying to create token.");
      setCreatingToken(false);
    } finally {
      setCreatingToken(false);
    }
  };

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
  useWaitForTransactionReceipt({
    hash,
  });

  // Determine which error to display
  const getFirstError = () => {
    if (errors.name) return errors.name;
    if (errors.ticker) return errors.ticker;
    if (errors.description) return errors.description;
    return null;
  };

  return (
    <PageContainer>
            <main className="h-full" style={{minHeight: '100vh'}}>
            <div className="flex flex-col justify-center items-center">
          <a
            className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 text-2xl text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50"
            href="/board"
          >
            [go back]
          </a>
          <div className="rounded-lg p-6 max-w-[420px] text-white">
            <div className="flex flex-col gap-4">
              <div className="nes-field">
                <label
                  className="mb-1 text-sm font-semibold text-blue-400"
                  htmlFor="name"
                >
                  name
                </label>
                <input
                  className="nes-input is-dark"
                  id="name"
                  placeholder=""
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="nes-field">
                <label
                  className="mb-1 text-sm font-semibold text-blue-400 "
                  htmlFor="ticker"
                >
                  ticker
                </label>
                <input
                  className="nes-input is-dark"
                  id="ticker"
                  placeholder=""
                  type="text"
                  value={formData.ticker}
                  onChange={handleInputChange}
                />
                {formData.ticker.length > 10 && (<div className="border border-red-300 text-red-300 rounded p-2" style={{ border: "1px solid", textAlign: "left" }}>ticker cannot be more than 10 characters</div>)}
              </div>
              <div className="nes-field">
                <label
                  className="mb-1 text-sm font-semibold text-blue-400"
                  htmlFor="description"
                >
                  description
                </label>
                <textarea
                  className="nes-textarea is-dark"
                  id="description"
                  placeholder=""
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="mb-1 text-sm font-semibold text-blue-400"
                  htmlFor="image"
                >
                  image
                </label>
                <input
                  className="bg-[#2a2a3b] border border-slate-200 rounded-md p-2 w-full"
                  id="image"
                  accept="image/*"
                  type="file"
                  onChange={handleImageChange}
                />
              </div>
              <div className="cursor-pointer hover:underline text-blue-400 w-fit" onClick={() => setToggleMoreOptions(!toggleMoreOptions)}>
                Show more options ↓
              </div>
              {toggleMoreOptions && (
                <>
                  <div className="nes-field">
                    <label className="mb-1 text-sm font-semibold text-blue-400" htmlFor="twitter">
                      twitter link
                    </label>
                    <input
                      className="nes-input is-dark"
                      id="twitter"
                      placeholder="(optional)"
                      type="text"
                      value={formData.twitter}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="nes-field">
                    <label
                      className="mb-1 text-sm font-semibold text-blue-400"
                      htmlFor="telegram"
                    >
                      telegram link
                    </label>
                    <input
                      className="nes-input is-dark"
                      id="telegram"
                      placeholder="(optional)"
                      type="text"
                      value={formData.telegram}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="nes-field">
                    <label className="mb-1 text-sm font-semibold text-blue-400" htmlFor="website">
                      website
                    </label>
                    <input
                      className="nes-input is-dark"
                      id="website"
                      placeholder="(optional)"
                      type="text"
                      value={formData.website}
                      onChange={handleInputChange}
                    />
                  </div>
                  <p className="text-xs">Tip: coin data cannot be changed after creation</p>
                </>
              )}
              {getFirstError() && (
                <div className="border border-red-300 text-red-300 rounded p-2" style={{ border: "1px solid", textAlign: "left" }}>
                  {getFirstError()}
                </div>
              )}
              <button className="nes-btn is-primary"
                onClick={handleSubmit}
                disabled={isConfirming || isPending || creatingToken}
                type="button"
              >
                {(isConfirming || isPending || creatingToken) ? "Creating..." : "Create coin"}
              </button>
              <div className="text-xs">
                To create a coin, you need to pay a small fee of 0.0001 ETH that will be used to fund the liquidity pool.
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </PageContainer>
  );
}
export default CreateToken;